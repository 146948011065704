import { FormattedMessage } from 'react-intl';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Box, CircularProgress } from '@mui/material';
import { withErrorBoundary } from '@sentry/react';
// import FmdBadRoundedIcon from '@mui/icons-material/FmdBadRounded';
import { useNavigate } from 'react-router-dom';
import useCrispChat from '../../../common/utils/chispChatHook';
import { RegistrationStepperMobile } from '../../../common/components/RegistrationStepper';
import {
  PAYMENT_STATUS_PROCESSING,
  PAYMENT_STATUS_FAILED,
  PAYMENT_STATUS_SUCCEEDED,
  PAYMENT_STATUS_CANCELED,
  PAYMENT_STATUS_EXPIRED,
  GOOGLE_CONVERSION_TAG_STEP_4,
  GOOGLE_CONVERSION_ACTION_PURCHASE,
  GOOGLE_CONVERSION_ID,
  API_ENDPOINT_PAYMENTS,
  API_ENDPOINT_REGISTRATIONS,
  API_ENDPOINT_RESTART,
  API_ENDPOINT_THANK_YOU,
  API_ENDPOINT_STATUS,
  API_ENDPOINT_VERIFY,
  LINKEDIN_CONVERSION_TAG_STEP_4
} from '../../../common/utils/constants';
import RegistrationPaymentStatus from './components/PaymentStatus';
import StripeCheckout from './components/StripeCheckout';
import { RegPaymentInfo } from '../../../app/interfaces';
import { readAuthToken } from '../../../common/utils/helpers';
import { callGtagEvent, setGtagVariables } from '../../../common/utils/handleGtag';
import { RegistrationPurchaseSummary } from '../../../common/components/RegistrationPurchaseSummary';
import { PaymentInfoState } from '../types';
import countriesData from '../../../common/utils/countriesData';
import callLinkedinTagEvent from '../../../common/utils/handleLinkedinTag';

interface PaymentStatusUIProps {
  regPaymentInfo: RegPaymentInfo;
  setRegPaymentInfo: Function;
  isRetryingPayment: boolean;
  setIsRetryingPayment: Function;
  handlePayment: Function;
}

function PaymentStatusUI({
  regPaymentInfo,
  setRegPaymentInfo,
  isRetryingPayment,
  setIsRetryingPayment,
  handlePayment
}: PaymentStatusUIProps) {
  const { payment } = regPaymentInfo;

  const retryStatuses = [
    PAYMENT_STATUS_PROCESSING,
    PAYMENT_STATUS_FAILED,
    PAYMENT_STATUS_CANCELED,
    PAYMENT_STATUS_EXPIRED
  ];
  if (retryStatuses.includes(payment.status) && !isRetryingPayment) {
    return (
      <RegistrationPaymentStatus
        setIsRetryingPayment={setIsRetryingPayment}
        regPaymentInfo={regPaymentInfo}
        handlePayment={handlePayment}
      />
    );
  }

  return (
    <StripeCheckout
      regPaymentInfo={regPaymentInfo}
      setRegPaymentInfo={setRegPaymentInfo}
      setIsRetryingPayment={setIsRetryingPayment}
    />
  );
}

function RegistrationPayment({ locale }: { locale: string }) {
  useCrispChat();

  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isRetryingPayment, setIsRetryingPayment] = useState<boolean>(false);
  const [regPaymentInfo, setRegPaymentInfo] = useState<RegPaymentInfo | null>(null);
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfoState>({
    base: 0,
    discount: 0,
    members: 0,
    total: 0,
    vat: 0,
    vat_percentage: 0,
    accountancy: false,
    privacy_policy: false
  });
  const [isLoading, setIsLoading] = useState(false);

  const handlePayment = async (action: 'restart' | 'read') => {
    try {
      setIsFetching(true);
      let endpoint;
      if (action === 'restart') {
        endpoint = `${API_ENDPOINT_REGISTRATIONS}${API_ENDPOINT_RESTART}`;
      }
      if (action === 'read') {
        endpoint = `${API_ENDPOINT_REGISTRATIONS}${API_ENDPOINT_STATUS}${API_ENDPOINT_VERIFY}`;
      }
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}${API_ENDPOINT_PAYMENTS}${endpoint}`,
        {
          method: 'POST',
          headers: {
            Authorization: readAuthToken(),
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
      const data = await response.json();
      if (data && !data.error && data.registration) {
        if (data.registration.payment.status === PAYMENT_STATUS_SUCCEEDED) {
          try {
            setGtagVariables({
              email: data.representative.email || '',
              address: {
                first_name: data.representative.first_name || '',
                last_name: data.representative.last_name || ''
              }
            });
            callGtagEvent(
              GOOGLE_CONVERSION_ID,
              GOOGLE_CONVERSION_TAG_STEP_4,
              GOOGLE_CONVERSION_ACTION_PURCHASE
            );
            callLinkedinTagEvent(LINKEDIN_CONVERSION_TAG_STEP_4);
            // eslint-disable-next-line no-empty
          } catch {}
          return navigate(`${API_ENDPOINT_THANK_YOU}/?is_switch=${data.registration.is_switch}`);
        }
        setRegPaymentInfo(data.registration);
      } else {
        return toast.error(data.message);
      }
    } catch (e) {
      return toast.error(`Error verifying payment ${(e as Error).message}`);
    }
    return setIsFetching(false);
  };

  const fetchData = async () => {
    if (regPaymentInfo && !regPaymentInfo.is_switch) {
      const members = regPaymentInfo.members.map((member) => {
        return {
          ...member,
          address_country: countriesData.find((country) => country.name === member.address_country)
            ?.code
        };
      });
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/company_registration/payments/estimate`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            data: {
              company: regPaymentInfo?.company ? regPaymentInfo?.company : null,
              members: members || []
            }
          })
        }
      );

      const data = await response.json();
      setIsLoading(false);
      if (!data.error) {
        setPaymentInfo({
          ...paymentInfo,
          base: data.base,
          vat: data.vat,
          discount: data.discount,
          vat_percentage: data.vat_percentage,
          members: data.members,
          total: data.total_with_vat
        });
      } else {
        toast.error(data.message);
      }
    }
  };

  fetchData();

  useEffect(() => {
    handlePayment('read');
  }, []);
  return (
    <div className="steps-container">
      <Box className="steps-step-container w-1/2 md:w-3/5 md:relative md:-top-6 md:pt-16 md:px-20 md:pb-12">
        <Box
          maxWidth="sm"
          className="flex-col justify-between items-center min-h-full min-w-full"
          sx={{ display: 'flex!important' }}>
          {/* desktop */}
          <Box
            className="hidden md:flex items-center justify-between"
            sx={{ maxWidth: '650px !important', width: '100%' }}>
            <a
              target="_blank"
              rel="noreferrer"
              href={locale === 'es' ? 'https://companio.co/es/' : 'https://companio.co/'}>
              <img
                src="/assets/images/companio-logo.png"
                width="123"
                alt="logo"
                style={{ maxHeight: '30px' }}
              />
            </a>
            <div className="flex items-center">
              <p className="text-gray-400 text-xs">
                <FormattedMessage id="having_trouble" defaultMessage="Having trouble?" />{' '}
                <a
                  href={
                    locale === 'es' ? 'https://help.companio.co/es' : 'https://help.companio.co/'
                  }
                  target="_blank"
                  style={{ fontWeight: 500, color: '#253292' }}
                  rel="noreferrer">
                  <FormattedMessage id="get_help" defaultMessage="Get help." />
                </a>
              </p>
              <img className="ml-1" src="/assets/icons/info.svg" alt="info" />
            </div>
          </Box>
          {/* mobile */}
          <Box className="flex md:hidden w-full justify-between align-center">
            <div> </div>
            <RegistrationStepperMobile currentStep={2} />
            <a
              href={locale === 'es' ? 'https://help.companio.co/es' : 'https://help.companio.co/'}
              target="_blank"
              style={{ fontWeight: 500, color: '#253292' }}
              rel="noreferrer">
              <img src="/assets/icons/info.svg" alt="info" />
            </a>
          </Box>
          {isFetching || !regPaymentInfo ? (
            <CircularProgress size={50} />
          ) : (
            <PaymentStatusUI
              isRetryingPayment={isRetryingPayment}
              setIsRetryingPayment={setIsRetryingPayment}
              regPaymentInfo={regPaymentInfo}
              setRegPaymentInfo={setRegPaymentInfo}
              handlePayment={handlePayment}
            />
          )}
          <div> </div>
        </Box>
      </Box>
      <Box
        className="steps-step-container md:fixed top-0 right-0 w-1/2 hidden md:flex md:flex-col md:items-center md:justify-between md:w-2/5"
        style={{
          background:
            regPaymentInfo && regPaymentInfo.is_switch
              ? 'linear-gradient(109.4deg, #018EFF 1.26%, #9A53CB 96.4%)'
              : 'linear-gradient(109.4deg, #FF4EA1 1.26%, #FEA500 96.4%)',
          padding: '40px'
        }}>
        <div className="hidden md:block" />
        <img className="md:hidden" src="/assets/images/companio-logo-white.png" alt="white-logo" />
        {!isLoading && (
          <RegistrationPurchaseSummary paymentInfo={paymentInfo} regPaymentInfo={regPaymentInfo} />
        )}
        <p style={{ fontSize: '14px', color: 'white' }}>
          © CompanioEstonia OÜ. All rights reserved.
        </p>
      </Box>
    </div>
  );
}

export default withErrorBoundary(RegistrationPayment, {
  fallback: <div>Failed to load</div>
});
