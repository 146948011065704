import { withErrorBoundary } from '@sentry/react';
import { Box } from '@mui/material';

import FmdBadRoundedIcon from '@mui/icons-material/FmdBadRounded';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { FormattedMessage } from 'react-intl';
import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useLocation } from 'react-router-dom';
import RegistrationButton from '../../common/components/RegistrationButton';
import {
  RegistrationStepper,
  RegistrationStepperMobile
} from '../../common/components/RegistrationStepper';
import useCrispChat from '../../common/utils/chispChatHook';
import { Localization } from './types';
import { toBooleanOrFalse } from '../../common/utils/validations';

const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

function RegisterThankYou({ setLocale, locale }: Localization) {
  const [showConfetti, setShowConfetti] = useState(true);
  const queryParams = useQueryParams();
  useCrispChat();

  useEffect(() => {
    setLocale(locale);
  });

  const isSwitch = toBooleanOrFalse(queryParams.get('is_switch'));

  return (
    <div className="steps-container">
      {showConfetti && (
        <Confetti
          data-testid="confetti"
          onConfettiComplete={() => {
            setShowConfetti(false);
          }}
        />
      )}
      <Box className="steps-form-container w-1/2 md:w-3/5 md:relative md:-top-6 md:pt-20 md:px-20 md:pb-12">
        <Box
          maxWidth="sm"
          className="items-center justify-between flex-col min-h-full min-w-full"
          sx={{ display: 'flex!important' }}>
          {/* desktop */}
          <Box
            className="hidden md:flex items-center justify-between"
            sx={{ maxWidth: '650px !important', width: '100%' }}>
            <a
              target="_blank"
              rel="noreferrer"
              href={locale === 'es' ? 'https://companio.co/es/' : 'https://companio.co/'}>
              <img
                src="/assets/images/companio-logo.png"
                width="123"
                alt="logo"
                style={{ maxHeight: '30px' }}
              />
            </a>
            <div className="flex items-center">
              <p className="text-gray-400 text-xs">
                <FormattedMessage id="having_trouble" defaultMessage="Having trouble?" />{' '}
                <a
                  href={
                    locale === 'es' ? 'https://help.companio.co/es' : 'https://help.companio.co/'
                  }
                  target="_blank"
                  style={{ fontWeight: 500, color: '#253292' }}
                  rel="noreferrer">
                  <FormattedMessage id="get_help" defaultMessage="Get help." />
                </a>
              </p>
              <FmdBadRoundedIcon style={{ color: '#253292' }} />
            </div>
          </Box>
          {/* mobile */}
          <Box className="flex md:hidden w-full justify-between">
            <ArrowBackIosNewIcon className="invisible" />
            <RegistrationStepperMobile currentStep={2} />
            <a
              href={locale === 'es' ? 'https://help.companio.co/es' : 'https://help.companio.co/'}
              target="_blank"
              style={{ fontWeight: 500, color: '#253292' }}
              rel="noreferrer">
              <img src="/assets/icons/info.svg" alt="info" />
            </a>
          </Box>
          <Box
            className="flex flex-col items-center justify-center mt-5"
            style={{ maxWidth: '500px', width: '100%' }}>
            <img
              src={
                isSwitch
                  ? '/assets/images/celebration_switching.svg'
                  : '/assets/images/celebration.svg'
              }
              alt="people"
              className="mx-auto"
            />
            <p className="registration-text-large-blue mt-10">
              {' '}
              <FormattedMessage
                id={isSwitch ? 'welcome_companio' : 'you_have_started_your_business_registration'}
                defaultMessage={
                  isSwitch
                    ? 'Welcome to Companio!'
                    : "Congratulations! You've started your business registration."
                }
              />{' '}
            </p>
            <p className="registration-text-medium-gray mt-5 mb-8" style={{ textAlign: 'center' }}>
              {' '}
              <FormattedMessage
                id={isSwitch ? 'save_time_with_us' : 'you_are_one_step_closer'}
                defaultMessage={
                  isSwitch
                    ? 'You’re on your way to saving time on accounting, taxes, and payroll so you can focus on what’s important.'
                    : "You're one step closer to launch and boost your business! 🤩"
                }
              />
            </p>
            <RegistrationButton
              className="!min-w-[280px]"
              size="large"
              /* eslint-disable-next-line no-return-assign */
              onClick={() =>
                (window.location.href = process.env.REACT_APP_FRONTEND_URL
                  ? process.env.REACT_APP_FRONTEND_URL
                  : '')
              }>
              <FormattedMessage id="lets_go_dashboard" defaultMessage="Let's go to Dashboard!" />
            </RegistrationButton>
          </Box>
          <div />
        </Box>
      </Box>

      <Box
        className="steps-step-container w-1/2 flex flex-col items-center justify-between md:w-2/5"
        style={{
          background: isSwitch
            ? 'linear-gradient(109.4deg, #018EFF 1.26%, #9A53CB 96.4%)'
            : 'linear-gradient(109.4deg, #FF4EA1 1.26%, #FEA500 96.4%)',
          padding: '40px'
        }}>
        <div className="hidden md:block" />
        <img className="md:hidden" src="/assets/images/companio-logo-white.png" alt="white-logo" />
        <RegistrationStepper currentStep={3} isSwitch={isSwitch} />
        <p style={{ fontSize: '14px', color: 'white' }}>
          © CompanioEstonia OÜ. All rights reserved.
        </p>
      </Box>
    </div>
  );
}

export default withErrorBoundary(RegisterThankYou, {
  fallback: <div>Failed to load</div>
});
