import { withStyles } from '@mui/styles';
import { Box, Step, StepLabel, Stepper as MUIStepper } from '@mui/material';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type CustomStepIconT = {
  active?: boolean;
  completed?: boolean;
  icon?: number;
};

const Stepper = withStyles({
  root: {
    '& .MuiStepLabel-label': {
      color: '#ffffff !important',
      fontWeight: '700 !important',
      fontsize: '19px !important',
      fontFamily: 'TEEE, sans-serif !important'
    },
    '& .MuiStepConnector-lineVertical': {
      marginLeft: '2px',
      borderLeftWidth: '2px',
      borderLeftStyle: 'dotted',
      borderLeftColor: '#ffffff',
      minHeight: '48px'
    }
  }
})(MUIStepper);

const MobileStepper = withStyles({
  root: {
    '& .MuiStep-horizontal': {
      padding: '0px'
    },
    '& .MuiStepLabel-iconContainer': {
      padding: '0px'
    },
    '& .MuiStepConnector-horizontal': {
      minWidth: '22px'
    }
  }
})(MUIStepper);

export function CustomStepIcon({ active, completed, icon }: CustomStepIconT) {
  if (active && icon === 4) {
    return <StarRoundedIcon style={{ width: '30px', height: '30px', color: '#F6D061' }} />;
  }
  if (completed) {
    return (
      <DoneRoundedIcon
        style={{
          width: '30px',
          height: '30px',
          color: '#fff',
          backgroundColor: '#00DA8B',
          borderRadius: '50%',
          padding: '2px'
        }}
      />
    );
  }
  if (active) {
    return (
      <CircleRoundedIcon
        style={{ width: '30px', height: '30px', color: '#F6D061', fill: 'white' }}
      />
    );
  }
  return <CircleOutlinedIcon style={{ width: '30px', height: '30px', color: 'white' }} />;
}

export function RegistrationStepper({
  currentStep,
  isSwitch
}: {
  currentStep: number;
  isSwitch: boolean;
}) {
  const steps = [
    {
      title: 'step_1_text',
      label: isSwitch ? 'switching_step_1_label' : 'registration_step_1_label'
    },
    {
      title: 'step_2_text',
      label: isSwitch ? 'switching_step_2_label' : 'registration_step_2_label'
    },
    {
      title: 'step_3_text',
      label: isSwitch ? 'switching_step_3_label' : 'registration_step_3_label'
    },
    {
      title: 'step_4_text',
      label: 'step_4_label'
    }
  ];

  return (
    <Box className="stepper" data-testid="registration_stepper">
      <p
        className="text-start text-white mb-5 md:mb-10"
        style={{ fontWeight: 700, fontSize: '24px' }}
        data-testid="registration_stepper_title">
        {' '}
        <FormattedMessage
          id={steps[currentStep].title}
          defaultMessage={`Step ${currentStep}`}
        />{' '}
      </p>
      <Stepper activeStep={currentStep} orientation="vertical">
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel StepIconComponent={CustomStepIcon}>
              <div className="w-full flex justify-between items-center">
                <p className="md:w-full">
                  <FormattedMessage id={step.label} defaultMessage={`Step ${currentStep}`} />{' '}
                </p>
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

export function CustomMobileStepIcon({ active, completed, icon }: CustomStepIconT) {
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: '2px',
    borderStyle: 'solid',
    width: '26px',
    height: '26px',
    fontSize: '14px',
    fontWeight: 700,
    borderRadius: '50px'
  };
  if (active && icon === 4) {
    return <StarRoundedIcon style={{ width: '30px', height: '30px', color: '#F6D061' }} />;
  }
  if (active) {
    return (
      <div
        data-testid="custom_mobile_step_active"
        style={{
          ...style,
          color: '#253292',
          borderColor: '#253292'
        }}>
        {' '}
        {icon}{' '}
      </div>
    );
  }
  if (completed) {
    return <CheckCircleRoundedIcon style={{ width: '30px', height: '30px', color: '#00DA8B' }} />;
  }
  return (
    <div
      data-testid="custom_mobile_step_default"
      style={{
        ...style,
        color: '#C5C7CD',
        borderColor: '#C5C7CD'
      }}>
      {' '}
      {icon}{' '}
    </div>
  );
}

export const getSteps = (currentStep: number) => {
  const steps = ['1', '2', '3', '4'];

  if (currentStep < 3) {
    steps.pop();
  }
  return steps;
};

export function RegistrationStepperMobile({ currentStep }: { currentStep: number }) {
  return (
    <MobileStepper activeStep={currentStep}>
      {getSteps(currentStep).map((step) => (
        <Step key={step}>
          <StepLabel StepIconComponent={CustomMobileStepIcon} />
        </Step>
      ))}
    </MobileStepper>
  );
}
