import { Box } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PaymentInfoState } from '../../features/main/types';
import { RegPaymentInfo } from '../../app/interfaces';
import { CompanyTypes } from '../../features/main/Steps/CompanyType';

export function RegistrationPurchaseSummary({
  paymentInfo,
  regPaymentInfo
}: {
  paymentInfo: PaymentInfoState;
  regPaymentInfo: RegPaymentInfo | null;
}) {
  const type = regPaymentInfo?.company.company_type;
  const priceMonthly = CompanyTypes.find(
    (companyType) => companyType.name === regPaymentInfo?.company.company_type
  )?.price;
  const intl = useIntl();

  return (
    <Box
      className="bg-white text-[#4B506D] rounded-md min-w-[500px] pt-8 text-left"
      data-testid="registration_stepper">
      <div className="px-8">
        <div className="flex pb-8">
          <img
            src={`/assets/images/icon-${type}.svg`}
            alt={`${type} icon`}
            width={50}
            className="pointer-events-none hidden lg:block mx-4 ml-0"
          />
          <div>
            <h2 className="text-xl font-semibold" style={{ color: '#253292' }}>
              <FormattedMessage id={`${type}_company_type`} defaultMessage={`${type} plan`} />
            </h2>
            <p className="font-medium" style={{ color: '#253292' }}>
              <FormattedMessage
                id="you_will_pay_monthly"
                defaultMessage={`You will pay €${priceMonthly} monthly`}
                values={{ price: priceMonthly }}
              />
            </p>
          </div>
        </div>
        <p className="pb-6 font-medium">
          <FormattedMessage id="company" />:{' '}
          <span className="font-normal">{regPaymentInfo?.company_name}</span>
        </p>
        <p className="pb-3 font-medium">
          <FormattedMessage id="members" />
        </p>
        {regPaymentInfo?.members.map((member, index) => {
          return (
            <p>
              {regPaymentInfo.is_switch ? (
                <FormattedMessage
                  id="member_item_switch"
                  values={{
                    number: index + 1,
                    name: `${member.first_name} ${member.last_name} `
                  }}
                />
              ) : (
                <FormattedMessage
                  id="member_item"
                  values={{
                    number: index + 1,
                    contribution: Number(member.shareholder_contribution).toFixed(2),
                    name: `${member.first_name} ${member.last_name} `
                  }}
                />
              )}
            </p>
          );
        })}
      </div>

      <div className="pb-8" />
      {PriceRow(
        intl.formatMessage({ id: 'subtotal' }),
        intl.formatMessage({ id: 'registration_price' }, { price: paymentInfo.base / 100 })
      )}
      {paymentInfo.members > 1 &&
        !regPaymentInfo?.is_switch &&
        PriceRow(intl.formatMessage({ id: 'extra_member' }), `€${(paymentInfo.members - 1) * 50}`)}
      {paymentInfo.vat > 0 &&
        PriceRow(
          intl.formatMessage({ id: 'applicable_vat', defaultMessage: 'Applicable VAT' }),
          `€${paymentInfo.vat / 100} (${paymentInfo.vat_percentage * 100}%)`
        )}
      {paymentInfo.discount > 0 &&
        PriceRow(
          intl.formatMessage({ id: 'discount', defaultMessage: 'Discount' }),
          `-€${paymentInfo.discount / 100}`
        )}
      {PriceRow('Total', `€${paymentInfo.total / 100}`, true, true)}
    </Box>
  );
}

export function PriceRow(
  label: string,
  value: string,
  highlighted?: boolean,
  roundedBotton?: boolean
) {
  return (
    <div
      className={`flex justify-between py-3 px-10 ${roundedBotton ? 'rounded-b-md' : ''}`}
      style={{ border: '1px solid #DFE0EB80', backgroundColor: highlighted ? '#DFE0EB' : '#FFF' }}>
      <span className="font-medium">{label}</span>
      <span className={`${!highlighted ? 'text-[#9FA2B4]' : ''}`}>{value}</span>
    </div>
  );
}
